







import { Component, Vue, Prop } from "vue-property-decorator";
@Component({})
export default class Titulo extends Vue {
  @Prop({ default: "" }) public TituloPantalla!: String;
}
